import React, { useContext, useState, useEffect } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { Link } from "react-router-dom"
import {Application, Applications, Integrations} from "../../classes"
import { useParams } from "react-router-dom";
import { Loader } from "../../component/Loader"
import { PopupImage } from "../../component/PopupImage";

export const IntegrationsItemPage = (props: any) => {
    const auth = useContext(GlobalContext)
    const [load, setLoad] = useState(true)
    const [loadBtn, setLoadBtn] = useState(false)
    const [update, setUpdate] = useState(false)
    const [toChat, setToChat] = useState(false)
    const [integration, setIntegration] = useState(new Integrations())
    const [application, setApplication] = useState(new Application({
        pk: 0,
        integration: integration,
        status: "",
        date: ""
    }))
    // const [appliList, setAppliList] = useState<Application[]>([{
    //     "pk": 0,
    //     "bloger": {
    //         "pk": 0,
    //         "name": "",
    //         "image": "",
    //         "integration": "",
    //         "subscribers": ""
    //     }
    // }])
    const [popupType, setPopupType] = useState("")
    const [popupError, setPopupError] = useState(true)
    const { id } = useParams();

    const [showPopUpImage, setShowPopUpImage] = useState(false)
    const [showPopUpURL, setPopUpURL] = useState("")
    const [isDisabled, setIsDisabled] = useState(false)

    const StatusUpdate = () => {
        integration.ChackStatus(auth.user.userToken).then(function(response){
            console.log(integration.applications)

            setLoad(false)
            if(response.applications != ""){
                application.pk = Number(response.applications)
                application.status = response.status
            }

            setLoadBtn(false)
            setUpdate(!update)
        }).catch(function(error){
            setLoadBtn(false)
            alert("error")
        })
    }

    useEffect(()=>{
        if(id)
            integration.Load(auth.user.userToken, id).then(function(response){
                StatusUpdate()
            }).catch(function(error){
                alert("error")
            })
    },[])

    useEffect(()=>{
        console.log(update)
    }, [update])


    const SwitchBtns = function(){
        if(integration.status == "В поиске исполнителя"){
            if(auth.user.userType == "bloger"){
                switch(integration.statusApplication){
                    case "none":
                        return (
                            <button onClick={()=>{
                                if(!loadBtn){
                                    setLoadBtn(true)
                                    integration.SwitchStaus(auth.user.userToken, "application").then(function(response){
                                        setPopupType(response.msg)
                                        setPopupError(response.error)
                                        StatusUpdate()
                                    }).catch(function(error){
                                        alert("error")
                                        setLoadBtn(false)
                                    })
                                }
                            }}>
                                Подать заявку
                            </button>
                        )
                    case "w8_bloger":
                        return (
                            <>
                                <button disabled={isDisabled} onClick={()=>{
                                    setIsDisabled(() => true)
                                    application.Respond(auth.user.userToken, true).then(function(response){
                                        setPopupType(response.msg)
                                        setPopupError(response.error)
                                        StatusUpdate()
                                        setToChat(response.error)
                                    }).catch(function(error){
                                        setLoadBtn(false)
                                        alert("error")
                                    })
                                }}>Принять</button>
                                <button disabled={isDisabled} onClick={()=>{
                                    setIsDisabled(() => true)
                                    application.Respond(auth.user.userToken, false).then(function(response){
                                        setPopupType(response.msg)
                                        setPopupError(response.error)
                                        StatusUpdate()
                                    }).catch(function(error){
                                        setLoadBtn(false)
                                        alert("error")
                                    })
                                }} style={{backgroundColor: "#3A0CA3"}}>Отклонить</button>
                            </>
                        )
                }
            }else{
                return (
                    <div></div>
                )
            }
        }else{
            return ""
        }
        return ""
    }

    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <section className="integration-item">
                <div className="content">
                    <h1 className="title">{integration.title}</h1>

                    {
                        integration.applications ? (
                            (integration.applications as Applications[]).length ? (
                                <div className="integration__applications-list">
                                    <h4>Заявки от блогеров</h4>
                                    <div className="wrapper">
                                        <div className="row">
                                            {
                                                (integration.applications as Applications[]).map((item)=>{
                                                    return(
                                                        <div className="col-md-6 col-lg-4">
                                                            <div className="applic-item">
                                                                <div className="applic-item__image">
                                                                    <div className="bloger-info__prize">
                                                                        <div className="bloger-info__prize-wrapper">
                                                                            <p>{item.bloger.integration}</p>
                                                                        </div>
                                                                    </div>
                                                                    <Link to={`/catalog/${item.bloger.pk}/`}>
                                                                        <div className="img" style={{
                                                                            backgroundImage: `url('http://api.blogerito.ru${item.bloger.image}')`
                                                                        }}></div>
                                                                    </Link>
                                                                </div>
                                                                <div className="applic-item__content">
                                                                    <div className="grid">
                                                                        <div>
                                                                            <h3>
                                                                                <Link style={{color: "#3A0CA3"}} to={`/catalog/${item.bloger.pk}/`}>
                                                                                    {item.bloger.name}
                                                                                </Link>
                                                                            </h3>
                                                                            <p><b>Подписчиков: </b>
                                                                                {
                                                                                    Number(item.bloger.subscribers) < 10000 ? (
                                                                                        Number(item.bloger.subscribers) < 2000 ? "Обзорщик":"Наноблогер"
                                                                                    ):"Микроблогер"
                                                                                }

                                                                            {}</p>
                                                                            <button onClick={()=>{
                                                                                integration.SwitchStaus(auth.user.userToken, "application", item.bloger.pk).then(function(response){
                                                                                    setPopupType(response.msg)
                                                                                    setPopupError(response.error)
                                                                                    StatusUpdate()
                                                                                    console.log(response.error)
                                                                                    setToChat(response.error)
                                                                                }).catch(function(error){
                                                                                    setLoadBtn(false)
                                                                                    alert("error")
                                                                                })
                                                                            }}>Принять заявку</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            ):""
                        ):""
                    }
                    <div className="row">
                        <div className="col-md-4">
                            <div className="integration-item__product">
                                <div
                                    className="integration-item__image"
                                    style={
                                        {
                                            backgroundImage: `url(${integration.productImage})`,
                                            cursor: "pointer"
                                        }
                                    }
                                    onClick={()=>{
                                        setShowPopUpImage(true)
                                        setPopUpURL(`${integration.productImage}`)
                                    }}
                                ></div>
                                <h2>{integration.productTitle}</h2>
                                <Link target="_blank" to={integration.productLink} className="myprogramms__more">Ссылка на товар</Link>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <h3 className="myprogramms__subtitle">Заказчик</h3>
                            <div className="integration-item__seller">
                                <div className="myprogramms__logo">
                                    <div className="myprogramms__logo-img" style={{backgroundImage: `url(${integration.seller.image})`}}></div>
                                    <div className="myprogramms__logo-content">
                                        <div className="grid">
                                            <div>
                                                <h4>{integration.seller.name}</h4>
                                                <p>{integration.seller.miniDesc}</p>
                                                <p className="item-desc">{integration.miniDesc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                integration.price > 0 ? (
                                    <p style={{
                                        backgroundColor: "#f8f4ff",
                                        display: "inline-block",
                                        padding: "5px 14px",
                                        borderRadius: 5,
                                        color: "black",
                                        fontSize: 14,
                                        fontWeight: "500"
                                    }}>
                                        Цена: {integration.price} руб.
                                    </p>
                                ):""
                            }

                            <p className="integration-item__desc">
                                <b>Дата создания: </b> {integration.date}<br/>
                                <b>Формат рекламы: </b>
                                {integration.format1 ? "рекламный пост, " : "" }
                                {integration.format2 ? "выкуп на маркетплейсе, " : "" }
                                {integration.format3 ? "отзыв на маркетплейсе, " : "" }
                                {integration.format5 ? "видеоотзыв на маркетплейсе, " : "" }
                                {integration.format4 ? "раздача товаров подписчикам " : "" }
                                <br/>
                                <b>Описание: </b>
                                {integration.desc}
                            </p>
                        </div>
                    </div>

                    <div className="integration-item__btn">
                        {SwitchBtns()}
                    </div>
                </div>
            </section>


            {
                popupType != "" ? (
                    <div className="popup-msg">
                        <div className="popup-msg__body">
                            <h3>{popupType}</h3>
                            {
                                toChat ? (
                                    <div style={{textAlign: "center"}}>
                                        <Link to={"/chat/"} className="popup-msg__link" style={{
                                            margin: "0px 3px",
                                            display: "inline-block",
                                            width: "auto"
                                        }}>
                                            Продолжить
                                        </Link>
                                        <div className="popup-msg__link" onClick={()=>{
                                            setPopupType("")
                                        }} style={{
                                            backgroundColor: "#9B51E0",
                                            margin: "0px 3px",
                                            display: "inline-block",
                                            width: "auto"
                                        }}>
                                            Остаться в интеграции
                                        </div>
                                    </div>
                                ):(
                                    <div className="popup-msg__link" onClick={()=>{
                                        setPopupType("")
                                    }}>
                                        Продолжить
                                    </div>
                                )
                            }
                        </div>
                    </div>
                ):""
            }


            {
                showPopUpImage ? (
                    <PopupImage setShowPopUpImage={setShowPopUpImage} showPopUpURL={showPopUpURL} />
                ):""
            }
        </>
    )
}
